<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'leaves' }">Leaves</router-link>
        </li>
        <li class="breadcrumb-item active">{{ searchString }}</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">
            {{ searchString.replace(/-/g, " ").toUpperCase() }} LEAVES
          </h2>
        </div>
        <div class="col-md-1 text-right"></div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div v-if="this.$store.state.loader">
            <loader
              object="#f74b3d"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="80"
              disableScrolling="false"
              name="spinning"
            ></loader>
          </div>
          <div v-else class="card">
            <div class="card-header">
              <flash-message class="myCustomClass"></flash-message>
            </div>
            <div class="card-body">
              <b-pagination
                v-model="currentPage"
                :total-rows="this.$store.state.filteredLeaves.length"
                :per-page="perPage"
                :current-page="currentPage"
                aria-controls="my-table"
              ></b-pagination>
              <b-table
                class="table table-custom"
                :items="leavesData"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                show-empty
                sticky-header
              >
                <template v-slot:cell(description)="data">
                  <p :title="data.item.reason">{{ data.item.reason }}</p>
                </template>
                <template v-slot:cell(from)="data">
                  {{ data.item.leavefrom }}
                </template>
                <template v-slot:cell(to)="data">
                  {{ data.item.leaveto }}
                </template>
                <template v-slot:cell(return_date)="data">
                  {{ data.item.returndate }}
                </template>
                <template v-slot:cell(action)="data">
                  <router-link
                    class="button button1"
                    v-if="
                      $store.state.permittedActions.includes(52) &&
                      data.item.status != 'Approved'
                    "
                    title="Edit"
                    :to="{ name: 'leave-update', params: { id: data.item.id } }"
                    ><i class="icon-pencil"></i
                  ></router-link>
                  <a
                    class="button button1 me-2"
                    title="Delete"
                    v-if="
                      $store.state.permittedActions.includes(53) &&
                      data.item.status != 'Approved'
                    "
                    @click.prevent="deleteLeave(data.item.id)"
                    ><i class="icon-bin"></i
                  ></a>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 15,
      searchString: this.$route.params.filterString,
      fields: [
        {
          key: "employee",
        },
        {
          key: "description",
          class: "custom-width",
        },
        {
          key: "leave_type",
          label: "Leave Type",
        },
        {
          key: "from",
          label: "Leave from",
        },
        {
          key: "to",
          label: "Leave To",
        },
        {
          key: "start_time",
          label: "Start time",
        },
        {
          key: "end_time",
          label: "End time",
        },
        {
          key: "return_date",
        },
        {
          key: "status",
        },
        {
          key: "action",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("loadFilterLeavesdata", this.searchString);
  },
  computed: {
    leavesData() {
      return this.$store.state.filteredLeaves;
    },
  },
  mounted() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadFilterLeavesdata", this.searchString);
  },
  methods: {
    formatTime(time) {
      return moment(time).format("hh:mm A");
    },
    deleteLeave(id) {
      if (confirm("Are you sure, you want to delete this leave ?")) {
        axios.get("/leaves/delete/" + id).then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$store.dispatch("loadLeavesdata");
          } else {
            this.flashMessage.error({
              message: "Something went wrong!",
              time: 3000,
            });
          }
        });
      }
    },
  },
};
</script>
